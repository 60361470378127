import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import detectorOptions from "./languageDetectorOptions";
import en from './languages/en.json';
import pl from './languages/pl.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: en,
  pl: pl,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    load: 'languageOnly',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: detectorOptions,
  });

  export default i18n;